<template>
  <div class="log">
    <!-- start 条件过滤区域 -->
    <div class="df-row">
      <div class="df-col">
        <!-- 日期选择器 -->
        <el-date-picker
          v-model="multipleDate"
          :clearable="false"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <!-- 日志类型选择器 -->
        <el-select
          v-model="submitData.kind_index"
          placeholder="请选择"
          size="mini"
          @change="onChangeKind"
        >
          <el-option
            v-for="item in kindList"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <!-- 日志项目选择器 -->
        <el-select
          v-model="submitData.item_index"
          placeholder="请选择"
          size="mini"
        >
          <el-option
            v-for="item in itemList"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <!-- 查询文本 -->
        <el-input
          v-model="submitData.word"
          size="mini"
          placeholder="请输入查询文本"
        />
      </div>

      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button type="primary" size="mini" @click="getLogList">
          查询
        </el-button>
      </div>

      <div class="df-col">
        <!-- 导出表格按钮 -->
        <el-button
          type="primary"
          size="mini"
          :disabled="excelDisabled"
          @click="onExportExcel"
          >导出表格</el-button
        >
      </div>
    </div>
    <!-- end 条件过滤区域 -->

    <!-- start 项目分类统计明细表 -->
    <el-table
      id="exprot-table"
      border
      stripe
      :data="logList"
      :max-height="tableMaxHeight"
      size="mini"
      v-loading="loading"
    >
      <el-table-column label="分类" prop="KindName"></el-table-column>
      <el-table-column label="项目" prop="ItemName"></el-table-column>
      <el-table-column
        label="内容"
        prop="LogContent"
        width="600"
      ></el-table-column>
      <el-table-column label="授权者" prop="AccreditName"></el-table-column>
      <el-table-column label="记账日期">
        <template slot-scope="scope">{{
          util.dateFormat("YYYY-mm-dd", new Date(scope.row.SoftwareDate * 1000))
        }}</template>
      </el-table-column>
      <el-table-column label="客户端时间">
        <template slot-scope="scope">{{
          util.dateFormat(
            "YYYY-mm-dd HH:MM",
            new Date(scope.row.ClientTime * 1000)
          )
        }}</template>
      </el-table-column>
      <el-table-column label="服务器时间">
        <template slot-scope="scope">{{
          util.dateFormat(
            "YYYY-mm-dd HH:MM",
            new Date(scope.row.ServerTime * 1000)
          )
        }}</template>
      </el-table-column>
      <el-table-column label="操作者" prop="OperatorName"></el-table-column>
    </el-table>
    <!-- end 项目分类统计明细表 -->

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[10, 15, 20, 25]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->
  </div>
</template>

<script>
import System from "@/api/system.js";

export default {
  data() {
    return {
      submitData: {
        // 提交数据
        begin_date: "", // 开始日期
        end_date: "", // 结束日期
        kind_index: 0, // 选中的类型value
        item_index: 0, // 选中的项目value
        word: "", // 关键词
        page_index: 1, // 页码
        page_size: 10, // 页数
      },
      kindList: [], // 日志类型
      itemList: [], // 操作项目
      logList: [], // 日志列表
      selectedItem: {}, // 选中欠款信息
      multipleDate: [], // 日期选择器数据
      totalRecord: 0, // 总页数
      tableMaxHeight: "", // 表格最大高度
      loading: false, // 表格加载
      excelDisabled: false, // 导出按钮禁用
    };
  },

  created() {
    this.getResize();
    this.initPageData();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.multipleDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.onChangeDate();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 90);
    },

    // 初始化数据
    async initPageData() {
      try {
        let { data } = await System.initLogList();
        let arr = [{ Name: "全部分类", Value: 0 }, ...data];
        this.kindList = arr;
        this.onChangeKind(0);
      } catch (err) {
        console.log(err);
      }
    },

    // 获取日志列表
    async getLogList() {
      this.loading = true;
      try {
        let { data } = await System.getLogList(this.submitData);
        this.totalRecord = data.filter.TotalRecord;
        this.logList = data.list;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 日志类型改变事件
    onChangeKind(event) {
      let index = this.kindList.findIndex((item) => item.Value == event);
      let arr = JSON.parse(JSON.stringify(this.kindList[index].Items || []));
      arr.unshift({ Name: "全部项目", Value: 0 });
      this.submitData.item_index = 0;
      this.itemList = arr;
    },

    // 日期选择器改变时间
    onChangeDate() {
      this.submitData.begin_date =
        this.multipleDate.length > 0
          ? this.multipleDate[0]
          : this.util.getNowMonthToday();
      this.submitData.end_date =
        this.multipleDate.length > 0
          ? this.multipleDate[1]
          : this.util.getNowMonthToday();
    },

    // 页数变化事件
    handleSizeChange(event) {
      this.submitData.page_size = event;
      this.submitData.page_index = 1;
      this.getLogList();
    },

    // 页码变化事件
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getLogList();
    },

    // 导出表格按钮点击事件
    onExportExcel() {
      this.excelDisabled = true;
      this.controlDisabled = false;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "操作日志");
        } finally {
          this.excelDisabled = false;
          this.controlDisabled = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.log {
  .el-pagination {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
